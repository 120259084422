/* src/ProgressBar.css */

.progress-bar-container {
    position: relative;
    width: 80%;
    height: 25px;
    background-color: #7594da;
    border-radius: 12.5px;
    overflow: hidden;
    margin: 20px auto;
    box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
}

.progress-bar-fill {
    height: 100%;
    background: #3468c8; /* Новый градиент */
    transition: width 0.2s ease-in-out;
}

.progress-bar-text {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    left: 0;
    line-height: 25px;
    font-weight: bold;
    color: #fff;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

/* Адаптивность */
/* @media (max-width: 768px) {
    .progress-bar-container {
        width: 80%;
        height: 20px;
    }

    .progress-bar-text {
        font-size: 0.8rem;
    }
} */
