html,
body,
#root {
    height: 100%;
    margin: 0;
}

* {
    font-family: 'Cabin', sans-serif;
}

body {
    margin: 0;
    padding: 0;
    font-family: Cabin;
    background-color: #18347e;
    color: white;
    min-height: 100vh;
    min-width: 320px;  /* Минимальная ширина для мобильных устройств */
    max-width: 100vw;  /* Максимальная ширина = ширина экрана */
    display: flex;
    flex-direction: column;
}

* {
    box-sizing: inherit;
}

#root {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* min-height: 100vh; */
    min-width: 320px;
    max-width: 100vw;

    background-size: cover; /* Растягивает изображение по ширине и высоте контейнера */
    background-position: center; /* Центрирует изображение */
    background-repeat: no-repeat; /* Отключает повторение изображения */
}

.item {
    /* background-color: #f0f0f0; */
    margin: 10px;
    width: 100%;
    text-align: center;
}

.header {
    margin-top: 30px;
    position: relative;
    height: 60px;
}

.left-image {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 100px;
}

.right-image {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 100px;
    height: 100px;
}

/* .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 1000;
} */

/* .left-image, */
/* .right-image {
    width: 100px;
    height: 100px;
}

.left-image {
    margin-left: 30px;
}

.right-image {
    margin-right: 30px;
} */



.chart-container {
    width: 100%; /* Занимает всю доступную ширину элемента item */
    height: 300px; /* Устанавливаем нужную высоту */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333; /* Пример цвета фона */
}

#rotating-image {
    width: 120px;
    height: 120px;
    animation: rotate 0.4s linear infinite;
}

#chart-airplane {
    position: absolute;
    width: 100px;
    height: 75px;
    top: 100%;
    left: 100%;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(180deg);
    }
}

.growth-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 5em;
    font-family: Roboto;
}

.btn {
    background: #108de7;
    border-radius: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    padding: 5px;
    text-align: center;
    width: 80%;
    color: white;
    border: none;
    height: 50px;
}

.text-container {
    width: 80%;
    margin-left: 50px;
    font-size: 24px;
}

.text-left {
    text-align: left;
}

.red {
    color: #e50539;
}

.blue {
    color: #1e89e3;
}

.btn-disabled {
    background-color: #47454682;
    color: gray;
}

.neon-text {
    color: #086479; /* Ярко-красный цвет текста */
    text-shadow:
    0 0 5px #34abc8,    /* Легкое свечение вокруг текста */
    0 0 10px #34abc8,   /* Среднее свечение */
    0 0 20px #34abc8;   /* Сильное свечение */
}

.neon-white-text {
    color: white; /* Ярко-красный цвет текста */
    text-shadow:
    0 0 5px #34abc8,    /* Легкое свечение вокруг текста */
    0 0 10px #34abc8,   /* Среднее свечение */
    0 0 20px #34abc8;   /* Сильное свечение */
}

.center-app {
    display: flex;
    flex-direction: column;
    justify-content: center;  /* Центрирование по вертикали */
    align-items: center;      /* Центрирование по горизонтали */
    min-height: 100vh;        /* Высота на весь экран */
    min-width: 100vw;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.center-box {
    background-color: #343434;
    border-radius: 10px;
    color: white;
    width: 90%;            /* Ширина 90% */
    height: 300px;         /* Высота 400 пикселей */
    text-align: center;
    
    /* Центрирование окна */
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

}

.game {
    position: relative;
    background-color: #0b153d;
    margin: 40px 40px;
    /* Отступы по бокам и сверху (от хедера) */
    height: 260px;
    border-radius: 10px;
    /* Скругление углов */
    /* margin-top: 50px; */
}

.footer-image {
    width: 100%;
    height: 50px;
    border-radius: 10px;
    background: none;
}

.game-footer {
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}

.game-footer {
    position: absolute;
    /* Абсолютное позиционирование внутри .game */
    bottom: -10px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.footer-block img {
    width: 100px;
    height: auto;
}

.game-content {
    position: relative;
    height: 85%;
    /* background: black; */
    margin-left: 15px;
    margin-right: 15px;
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.game-content-wrapper {
    position: relative;
    padding-right: 30px;
    padding-left: 30px;
}

.game-left,
.game-right {
    position: absolute;
    /* Позволяет позиционировать элементы внутри .game-content-wrapper */
    top: 60%;
    /* Вертикальное центрирование */
    transform: translateY(-50%);
    /* Для точного вертикального центрирования */
}

.game-left {
    left: 0;
    bottom: -20px;
    /* Прижимаем элемент к левому краю */
}

.footer-left-image {
    width: 75px;
    height: 150px;
}

.game-right {
    right: 0;
    /* Прижимаем элемент к правому краю */
}

.game-grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* Сетка 5x5 */
    grid-gap: 1px;
    width: 100%;
}

.game-cell {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #fff; */
    border-radius: 5px;
    overflow: hidden;
}

.game-cell img {
    width: 100%;
    height: auto;
}

.bold-text {
    font-family: 'Montserrat', sans-serif; 
    font-weight: 700;
}

.stats-container {
    width: 80%; 
    text-align: left;
    color: #dce2f0e6;
    margin-left: 40px;
}